import styled, { css } from 'styled-components';
import { Modal as IframeModalBase } from 'Components/molecules/Modal/Modal';

export const Wrapper = styled.div<{ isLoading: boolean }>`
  ${({ isLoading }) => !isLoading && 'border: 0.0625rem solid #d8d8d8'};
  border-radius: 0.3125rem;
  position: relative;
  background-color: ${({ theme }) => theme.colorMappings.chartBackground};
  height: fit-content !important;
  max-height: 820px;

  .highcharts-tracker.packedBubble > .highcharts-point {
    fill: transparent;
    stroke: transparent;
  }
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.875rem;
  margin-bottom: 1.25rem;
  max-width: 35rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    margin: 0;
    display: inline;
  }
`;

export const ResizeContainer = styled.div<{ isMaxWidth: boolean }>`
  width: 1.75rem;
  height: 4.3125rem;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: 0.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -0.8125rem;
  top: calc(50% - 2.1875rem);
  z-index: 99;
  background-color: #fff;
  cursor: pointer;
  ${({ isMaxWidth }) =>
    !isMaxWidth &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

export const Modal = styled.div`
  font-size: 0.8rem;
  background-color: ${({ theme }) => theme.colors.white[500]};
  width: 18rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey[700]};
  z-index: 999;
  position: absolute;
  box-shadow: 0 0 0.4375rem 0.0625rem rgba(0 0 0 / 15%);
  word-wrap: normal;
  line-break: normal;
  white-space: break-spaces;
`;

export const IframeModal = styled(IframeModalBase)`
  > div {
    > div:not(:first-child) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      width: 100%;
      > div {
        display: flex;
        justify-content: flex-end;
        align-items: end;
        margin-top: 1rem;
        height: auto;
        width: 100%;
      }
    }
  }

  p {
    font-size: 0.8rem;
    background-color: ${({ theme }) => theme.colors.grey[100]};
    padding: 1rem;
    border: 0.0625rem solid ${({ theme }) => theme.colors.grey[300]};
    word-break: break-all;
    margin-bottom: 25%;
  }
`;
export const ButtonWrapper = styled.div``;
