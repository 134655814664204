import { Options } from 'highcharts';
import { theme } from 'Client/components/theme';
import { ChartDefinitionProps } from '../types';
import { sharedOptions } from './sharedDefinitions';
import { createCSVName, renderTooltipFormatter } from './utils';

export const generateWordCloudChartOptions = ({
  ...props
}: ChartDefinitionProps): Options => {
  const { wordcloudData, questionTitle } = props;

  return {
    caption: {
      style: {
        fontFamily: theme.fontFamily,
      },
    },
    chart: {
      type: 'wordcloud',
      renderTo: 'container',
      backgroundColor: '#FAFAFA',
      style: {
        fontFamily: theme.fontFamily,
      },
      animation: false,
    },
    plotOptions: {
      series: {
        animation: false,
      },
    },
    ...sharedOptions(),
    colors: [
      '#5891FF',
      '#B9DB66',
      '#F8D757',
      '#D594BE',
      '#00BFB2',
      '#6146A3',
      '#90B3C8',
      '#10EAB5',
      '#E9843B',
      '#EB549F',
      '#787ADE',
      '#98D4FB',
      '#3BE46A',
      '#FFA756',
      '#F09694',
      '#9990FF',
      '#539773',
      '#D2C674',
      '#FF6D6D',
      '#B656CE',
      '#5672A3',
    ],
    tooltip: {
      shape: 'rect',
      useHTML: true,
      borderWidth: 0,
      backgroundColor: '#fff',
      formatter: function () {
        return renderTooltipFormatter(this, this.key, '', this.point['weight']);
      },
    },
    exporting: {
      filename: createCSVName(props.questionTitle),
    },
    title: {
      text: questionTitle,
    },
    series: [
      {
        type: 'wordcloud',
        data: wordcloudData,
        maxFontSize: 50,
        minFontSize: 8,
        style: {
          //fontFamily needs to be defined here because of the PNG and JPEG exports
          fontFamily: 'Arial',
        },
        rotation: {
          from: 0,
          to: 0,
          orientations: 1,
        },
        name: 'Occurrences',
      },
    ],
  };
};
